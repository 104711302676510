import { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";

import LogoLight from "../assets/images/logo-new/IMOVISOR-light.png";
import LogoDark from "../assets/images/logo-new/IMOVISOR-dark.png";

// import index1 from '../assets/images/demos/hero-one.png'
// import index2 from '../assets/images/demos/hero-two.png'
// import index3 from '../assets/images/demos/hero-three.png'
// import index4 from '../assets/images/demos/hero-four.png'
// import index5 from '../assets/images/demos/hero-five.png'
// import index6 from '../assets/images/demos/hero-six.png'
// import index7 from '../assets/images/demos/hero-seven.png'
// import index8 from '../assets/images/demos/hero-eight.png'
// import index9 from '../assets/images/demos/hero-nine.png'

import { FiUser } from "react-icons/fi";

export default function Navbar(props) {
    const { navClass, topnavClass, tagLine } = props;

    let [ toggle, setToggle] = useState(false);
    let [manu , setManu] = useState('');
    let [subManu , setSubManu] = useState('');
    let [ scroll, setScroll ] = useState(false)

    const location = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
        let current = location.pathname
        setManu(current)
        setSubManu(current)

        let windowScroll =()=>{
            setScroll(window.scrollY > 50);
        }
        window.addEventListener('scroll', windowScroll )

        return()=>{
            window.removeEventListener('scroll', windowScroll )
        }
    },[location.pathname])


    // render() {
    // const toggleClass = this.state.isOpenMenu ? 'hidden' : 'block';


    return (
        <>
            <nav id="topnav" className={`defaultscroll is-sticky ${scroll ? 'nav-sticky' : ''} ${tagLine ? 'tagline-height' : ''} ${topnavClass ? topnavClass : ''} 
            `} >
                <div className={`${topnavClass !== '' && topnavClass !== undefined ? 'container-fluid md:px-8 px-3' : 'container'}`}>
                    {/* <!-- Logo container--> */}
                    {navClass === '' || navClass === undefined ?
                        <Link className="logo" to="/">
                            <img src={LogoDark} className="inline-block dark:hidden" alt="Logo Dark Imovisor" />
                            <img src={LogoLight} className="hidden dark:inline-block" alt="Logo Light Imovisor" />
                        </Link> :
                        <Link className="logo" to="#">
                            <span className="inline-block dark:hidden">
                                <img src={LogoDark} className="l-dark" height="24" alt="Logo Dark Imovisor" />
                                <img src={LogoLight} className="l-light" height="24" alt="Logo Light Imovisor" />
                            </span>
                            <img src={LogoLight} height="24" className="hidden dark:inline-block" alt="Logo Light Imovisor" />
                        </Link>
                    }
                    {/* <!-- End Logo container--> */}

                    {/* <!-- Start Mobile Toggle --> */}
                    <div className="menu-extras">
                        <div className="menu-item">
                            <Link to="#" className={`${ toggle ? 'open' : '' } navbar-toggle`} id="isToggle" onClick={() =>setToggle(!toggle)} >
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </div>
                    </div>
                    {/* <!-- End Mobile Toggle --> */}

                    {/* <!-- Login button Start --> */}
                    <ul className="buy-button list-none mb-0">
                        <li className="inline mb-0">
                            <Link to="https://app.imovisor.com/" target="_blank" className="btn btn-icon bg-green-600 hover:bg-green-700 border-green-600 dark:border-green-600 text-white rounded-full"><FiUser className="size-4 stroke-[3]"/></Link>
                        </li>
                        <li className="sm:inline ps-1 mb-0 hidden">
                            <Link to="https://app.imovisor.com/" target="_blank" className="btn bg-green-600 hover:bg-green-700 border-green-600 dark:border-green-600 text-white rounded-full">Acessar</Link>
                        </li>
                    </ul>
                    {/* <!--Login button End--> */}

                    <div id="navigation" style={{ display: toggle ? 'block' : 'none' }}>
                        {/* <!-- Navigation Menu--> */}
                        <ul className={`navigation-menu  ${navClass === '' || navClass === undefined ? '' : 'nav-light'}   ${topnavClass !== '' && topnavClass !== undefined ? 'justify-center' : 'justify-end'}`}>
                            <li className={`has-submenu parent-menu-item ${["/" ,"/index", "/index-two", "/index-three", "/index-four", "/index-five", "/index-six", "/index-seven","/index-eight","/index-nine"].includes(manu) ? 'active' : ''}`}>
                                <Link to="#" onClick={(e)=>{setSubManu(subManu === "/index-item" ? "" : "/index-item")}}>Sobre</Link>
                            </li>

                            <li className={manu ===  "/#!" ? 'active' : '' }><NavLink to="/#!" activeclassname="active" className="sub-menu-item">Planos</NavLink></li>

                            <li className={manu ===  "/#!" ? 'active' : '' }><Link to="/#!" className="sub-menu-item">Soluções</Link></li>

                            <li className={manu ===  "/#!" ? 'active' : '' }><NavLink to="/#!" activeclassname="active" className="sub-menu-item">Blog</NavLink></li>

                            <li className={manu ===  "/#!" ? 'active' : '' }><NavLink to="/#!" activeclassname="active" className="sub-menu-item">Contato</NavLink></li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* End Navbar  */}
        </>
    );

}

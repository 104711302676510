import { Link } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { FiFacebook, FiInstagram, FiLinkedin, FiMail, FiPhone, FiTwitter } from "react-icons/fi";

import LogoLight from "../assets/images/logo-new/IMOVISOR-light.png";

export default function Footer() {

    return (
        <>
            <footer className="relative bg-slate-900 dark:bg-slate-800 mt-24">
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="relative py-16">
                            {/* <!-- Subscribe --> */}
                            <div className="relative w-full">
                                <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                    <div className="lg:col-span-4 md:col-span-12">
                                        <Link to="/" className="text-[22px] focus:outline-none">
                                            <img src={LogoLight} alt="Logo Imovisor" />
                                        </Link>
                                        <p className="mt-6 text-gray-300">A plataforma de busca rápida e eficiente que conecta você aos melhores imóveis na sua região.</p>

                                    </div>

                                    <div className="lg:col-span-2 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Empresa</h5>
                                        <ul className="list-none footer-list mt-6">
                                            <li><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Sobre nós</Link></li>
                                            <li className="mt-[10px]"><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Planos</Link></li>
                                            <li className="mt-[10px]"><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Soluções</Link></li>
                                            <li className="mt-[10px]"><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Blog</Link></li>
                                            <li className="mt-[10px]"><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Plataforma</Link></li>
                                        </ul>
                                    </div>

                                    <div className="lg:col-span-3 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Links Úteis</h5>
                                        <ul className="list-none footer-list mt-6">
                                            <li><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Termos de serviço</Link></li>
                                            <li className="mt-[10px]"><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Políticas de privacidade</Link></li>
                                            <li className="mt-[10px]"><Link to="/#!" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out flex items-center"><MdKeyboardArrowRight className="me-1 text-xl"/> Contato</Link></li>
                                        </ul>
                                    </div>

                                    <div className="lg:col-span-3 md:col-span-4">
                                        <h5 className="tracking-[1px] text-gray-100 font-semibold">Contato</h5>
                                        <div className="flex mt-6">
                                            <FiMail className="size-5 text-green-600 me-3"/>
                                            <div className="">
                                                <Link to="mailto:contact@example.com" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">contato@imovisor.com</Link>
                                            </div>
                                        </div>

                                        <div className="flex mt-6">
                                            <FiPhone className="size-5 text-green-600 me-3"/>
                                            <div className="">
                                                <Link to="tel:+152534-468-854" className="text-slate-300 hover:text-slate-400 duration-500 ease-in-out">+55 41 534-468-854</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Subscribe --> */}

                        </div>
                    </div>
                </div>

                <div className="py-[30px] px-0 border-t border-gray-800 dark:border-gray-700">
                    <div className="container text-center">
                        <div className="grid md:grid-cols-2 items-center gap-6">
                            <div className="md:text-start text-center">
                                <p className="mb-0 text-gray-300">© <script type="text/javascript" id="www-widgetapi-script" src="https://www.youtube.com/s/player/d87d581f/www-widgetapi.vflset/www-widgetapi.js"></script><script id="iframe_api" src="https://www.youtube.com/iframe_api"></script>{(new Date().getFullYear())}{" "} Imovisor. Desenvolvido com <i className="mdi mdi-heart text-red-600"></i> por <Link to="#" target="_blank" className="text-reset">Equipe Imovisor</Link>.</p>
                            </div>

                            <ul className="list-none md:text-end text-center">
                                <li className="inline ms-1"><Link to="#" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiLinkedin className="size-4"/></Link></li>
                                <li className="inline ms-1"><Link to="#" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiFacebook className="size-4"/></Link></li>
                                <li className="inline ms-1"><Link to="#" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiInstagram className="size-4"/></Link></li>
                                <li className="inline ms-1"><Link to="#" target="_blank" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiTwitter className="size-4"/></Link></li>
                                <li className="inline ms-1"><Link to="mailto:contato@imovisor.com" className="btn btn-icon btn-sm text-gray-400 hover:text-white border border-gray-800 dark:border-gray-700 rounded-md hover:border-green-600 dark:hover:border-green-600 hover:bg-green-600 dark:hover:bg-green-600"><FiMail className="size-4"/></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
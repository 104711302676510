import { Link } from "react-router-dom";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";

import vda from '../../assets/images/regioes/vda-400px.jpg';
import bh from '../../assets/images/regioes/bh-400px.jpg';
import curitiba from '../../assets/images/regioes/curitiba-400px.jpg';
import sp from '../../assets/images/regioes/sp-400px.jpg';

export const teamdata = [
    {
        image: vda,
        name: 'Vale do Aço',
        title: 'Ativo'
    },
    {
        image: bh,
        name: 'Belo Horizonte',
        title: 'Em breve'
    },
    {
        image: curitiba,
        name: 'Curitiba',
        title: 'Em breve'
    },
    {
        image: sp,
        name: 'São Paulo',
        title: 'Em breve'
    },
]

export default function Team() {
    return (
      <div className="container relative lg:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-8 text-center">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Onde estamos presentes</h3>

              <p className="text-slate-400 max-w-xl mx-auto">Estamos trabalhando para atender todas as principais regiões do Brasil.</p>
          </div>

          <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
              {teamdata.map((item, index) =>{
                  return(
                      <div className="lg:col-span-3 md:col-span-6" key={index}>
                          <div className="group text-center">
                              <div className="relative inline-block mx-auto size-52 rounded-full overflow-hidden">
                                  <img src={item.image} className="" alt={`Região ${item.title}`} />
                                  <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black size-52 rounded-full opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out"></div>

                                  <ul className="list-none absolute start-0 end-0 -bottom-20 group-hover:bottom-5 transition-all duration-500 ease-in-out">
                                      <li className="inline ms-1"><Link to="#" className="btn btn-icon btn-sm rounded-full border border-green-600 bg-green-600 hover:border-green-600 hover:bg-green-600 text-white"><FiFacebook className="size-4" /></Link></li>
                                      <li className="inline ms-1"><Link to="#" className="btn btn-icon btn-sm rounded-full border border-green-600 bg-green-600 hover:border-green-600 hover:bg-green-600 text-white"><FiInstagram className="size-4" /></Link></li>
                                      <li className="inline ms-1"><Link to="#" className="btn btn-icon btn-sm rounded-full border border-green-600 bg-green-600 hover:border-green-600 hover:bg-green-600 text-white"><FiLinkedin  className="size-4" /></Link></li>
                                  </ul>
                              </div>

                              <div className="content mt-3">
                                  <Link to="#" className="text-xl font-medium hover:text-green-600 transition-all duration-500 ease-in-out">{item.name}</Link>
                                  <p className="text-slate-400">{item.title}</p>
                              </div>
                          </div>
                      </div>
                  )
              })}
          </div>
      </div>
    )
}
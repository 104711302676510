import { FiHexagon, FiFilter } from "react-icons/fi";
import { WiStars } from "react-icons/wi";
import { TbDatabasePlus } from "react-icons/tb";

const featureData = [
    {
        icon:FiFilter,
        title:'Busca de Imóveis com Filtros Avançados',
        desc: 'Encontre imóveis de forma precisa com filtros que incluem localização, preço, tipo de imóvel, e muito mais, para garantir que você visualize apenas o que realmente atende às suas necessidades.'
    },
    {
        icon:WiStars,
        title:'Busca com Inteligência Artificial',
        desc: 'Nossa inteligência artificial interpreta a linguagem natural, permitindo que você faça buscas intuitivas e informais, como "apartamento com varanda no centro", para encontrar resultados alinhados à sua intenção.'
    },
    {
        icon:TbDatabasePlus,
        title:'Atualizações Constantes e Dados Confiáveis',
        desc: 'Com tecnologias de coleta de dados, o Imovisor mantém a listagem de imóveis sempre atualizada, reunindo informações de diversas fontes para que você tenha acesso às opções mais recentes e relevantes.'
    },
];

export default function Feature() {
    return (
        <>
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Funcionalidades Principais</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Um bom lugar para buscar entre milhares de imóveis com filtros avançados, IA e muito mais.</p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                    {featureData.map((item,index) =>{
                        let Icon = item.icon
                        return(
                            <div className="group relative lg:px-10 transition-all duration-500 ease-in-out rounded-xl bg-transparent overflow-hidden text-center" key={index}>
                                <div className="relative overflow-hidden text-transparent -m-3">
                                    <FiHexagon className="size-32 fill-green-600/5 mx-auto" />
                                    <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-green-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                                        <Icon className="text-3xl"/>
                                    </div>
                                </div>
        
                                <div className="mt-6">
                                    <h5 className="text-xl font-medium">{item.title}</h5>
                                    <p className="text-slate-400 mt-3">{item.desc}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

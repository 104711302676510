import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';

import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
// import Switcher from "../../components/Switcher";

import bg1 from '../../assets/images/bg/01.jpg'
import bg2 from '../../assets/images/bg/02.jpg'

// import SearchFilter from './SearchFilter';
// import PropertyList from './PropertyList';
// import Client from "./Client";
import Stats from './Stats';
import Team from "./Team";
import About from "./About";
import Feature from "./Feature";
import GetInTouch from "./Get-in-touch";

export default function Index(){
    return(
        <>
        <Navbar navClass="navbar-white"/>
        <section className="swiper-slider-hero relative block h-screen" id="home">
        <Carousel style={{height:'100%'}} autoPlay={false} infiniteLoop={false} showArrows={false} showStatus={false} showThumbs={false}>
            <div className="swiper-slide flex items-center overflow-hidden" style={{height:'100%'}}>
                    <div className="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image flex items-center bg-center"  style={{backgroundImage:`url(${bg1})`, height:'100%'}}>
                        <div className="absolute inset-0 bg-black/70"></div>
                        <div className="container relative">
                            <div className="grid grid-cols-1">
                                <div className="text-center">
                                    <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Imóveis Centralizados, Busca Inteligente</h1>
                                    <p className="text-white/70 text-xl max-w-xl mx-auto">Imóveis de várias fontes em um só lugar. Para corretores e clientes que valorizam organização e simplicidade.</p>
                                    
                                    <div className="mt-6">
                                        <Link to="" className="btn bg-green-600 hover:bg-green-700 text-white rounded-md">Veja os Imóveis Disponíveis</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 

                {/* <div className="swiper-slide flex items-center overflow-hidden">
                    <div className="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image flex items-center bg-center" style={{backgroundImage:`url(${bg2})`}}>
                        <div className="absolute inset-0 bg-black/70"></div>
                        <div className="container relative">
                            <div className="grid grid-cols-1">
                                <div className="text-center">
                                    <h1 className="font-bold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-6">Todos os Imóveis em <br/> Um Só Lugar</h1>
                                    <p className="text-white/70 text-xl max-w-xl mx-auto">A melhor plataforma para encontrar e gerenciar imóveis, conectando corretores, imobiliárias e clientes.</p>
                                    
                                    <div className="mt-6">
                                        <Link to="" className="btn bg-green-600 hover:bg-green-700 text-white rounded-md">Veja os Imóveis Disponíveis</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  */}
            </Carousel>
        </section>

        <section className="relative md:pb-24 pb-16">
            {/* <SearchFilter /> */}
            <About />
            <Feature />
            {/* <PropertyList /> */}
            <Stats />
            <Team />
            {/* <Client /> */}
            <GetInTouch />
        </section>
        <Footer/>
        {/* <Switcher/> */}
        </>
    )
}
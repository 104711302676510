import React, { useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

import './App.css';

import Index from './pages/index';
import About from './pages/about';
import ScrollToTop from './components/Scroll-top';

function App() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
  }, []);

  return (
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/about" element={<About />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;

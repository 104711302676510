import CountUp from 'react-countup';

export const counterData = [
    {
        target:'11548',
        title: 'Imóveis a Venda'
    },
    {
        target:'9745',
        title: 'Imóveis para Alugar'
    },
    {
        target:'5',
        title: 'Regiões Atendidas'
    },
]

export default function Stats() {
    return (
      <div className="container relative lg:mt-24 mt-16 lg:pt-24 pt-16">
          <div className="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.png')] bg-no-repeat bg-center bg-cover"></div>
          <div className="relative grid grid-cols-1 pb-8 text-center z-1">
              <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Milhares de novos imóveis todos os dias</h3>

              <p className="text-slate-400 max-w-xl mx-auto">A primeira e única plataforma que consegue buscar imóveis em todos os lugares</p>
          </div>

          <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
              {counterData.map((item,index) =>{
                  return(
                      <div className="counter-box text-center" key={index}>
                          <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-slate-400 dark:text-white"><CountUp start={0} end={item.target}/>+</h1>
                          <h5 className="counter-head text-lg font-medium">{item.title}</h5>
                      </div>
                  )
              })}
          </div>
      </div>
    )
}
